<template>
    <div class="home">
        <v-card>
            <v-container fluid>
                <v-row><v-col cols="12"></v-col></v-row>
                    <v-row>
                        <v-col cols="12">
                            <v-card outlined>
                                <v-card-title style="border-bottom: 1px solid #404040;" class="pt-2 pb-2">
                                    <div class="text-left" style="margin-left: 0; width: 50%; font-size: 16px; font-weight: normal;">
                                        SYMBOL RULE FILTER
                                    </div>
                                </v-card-title>
                                <v-row class="pa-4">
                                    <v-col cols="12" sm="12" md="12" lg="12" xl="12">
                                        <v-alert text class="mt-2 mb-0" color="info" icon="mdi-cloud-alert" border="left">
                                            <p class="mb-0"> The symbol rule is used to merge all same prefix symbol that calculated in PK monitor page.</p>
                                            <p class="mb-0"> For example: XAUUSD-E, XAUUSD-S, XAUUSD-H will merge as XAUUSD as -E, -S, -H are all defined in below table.</p>
                                        </v-alert>
                                    </v-col>
                                </v-row>
                                <v-row class="pa-4">
                                    <v-col cols="12" sm="12" md="4" lg="4" xl="4" v-if="brokerMap.length > 1">
                                        <v-autocomplete label="Filter Broker"
                                            v-model="selectedBrokerId" :items="brokerMap" item-text="broker" item-value="broker_id"
                                            auto-select-first chips clearable deletable-chips small-chips></v-autocomplete>
                                    </v-col>

                                    <v-col cols="12" sm="12" md="12" lg="12" xl="12">
                                        <v-btn class="mr-3" v-on:click="triggerNew()" color="primary">ADD RULE</v-btn>
                                        <v-btn v-on:click="triggerRemove()"
                                            :disabled="tableData.selectedData.length === 0"
                                            color="red">REMOVE</v-btn>
                                    </v-col>
                                </v-row>
                                <v-row class="pa-4">
                                    <v-col cols="12" sm="12" md="12" lg="12" xl="12">
                                        <v-text-field class="mt-n2 mb-2" v-model="tableData.search" append-icon="mdi-search" label="Search" single-line hide-details>
                                        </v-text-field>
                                        <v-data-table dense :search="tableData.search" :headers="tableData.headers" :items="tableData.dataSource" class="elevation-1" :single-select="false" show-select v-model="tableData.selectedData" :items-per-page="tableData.itemsPerPage">

                                            <!--
                                            <template v-slot:item.action="{ item }">
                                                <a @click=""><v-icon color="red">mdi-delete-empty</v-icon></a>
                                            </template> -->

                                        </v-data-table>
                                    </v-col>
                                </v-row>

                                <v-dialog v-model="modalNew.visible" max-width="800">
                                    <v-card>
                                        <v-card-title class="headline">
                                            {{ modalNew.title }}
                                        </v-card-title>

                                        <v-card-actions>
                                            <v-row>
                                                <v-col cols="12" sm="12" md="12" lg="12" xl="12">
                                                    <v-textarea filled name="input-7-4" label="Symbol Rule (Seprate by Comma, Eg: -r, -s, -e)"
                                                        v-model="modalNew.symbolRule" rows="4">
                                                    </v-textarea>
                                                </v-col>
                                            </v-row>
                                        </v-card-actions>

                                        <v-card-actions>
                                            <v-spacer></v-spacer>
                                            <v-btn color="green darken-1" text @click="modalNew.visible = false">Cancel</v-btn>
                                            <v-btn color="green darken-1" text @click="symbolRuleHandler().update()"
                                                :loading="modalNew.loading">Add</v-btn>
                                        </v-card-actions>
                                    </v-card>
                                </v-dialog>

                                <v-dialog v-model="modalRemove.visible" max-width="800">
                                    <v-card>
                                        <v-card-title class="headline">{{ modalRemove.title }}</v-card-title>

                                        <v-card-actions>
                                            <v-row>
                                                <v-col cols="12" sm="12" md="12" lg="12" xl="12">
                                                    <v-alert text  color="red" icon="mdi-cloud-alert" border="left">
                                                        <p>Are you sure to remove these symbol rules?</p>
                                                        <p>
                                                            <span v-for="item in tableData.selectedData" :key="item.id">
                                                                "{{ item.endfix }}",
                                                            </span>
                                                        </p>
                                                    </v-alert>
                                                </v-col>
                                            </v-row>
                                        </v-card-actions>

                                        <v-card-actions>
                                            <v-spacer></v-spacer>
                                            <v-btn color="green darken-1" text @click="modalRemove.visible = false">Cancel</v-btn>
                                            <v-btn color="red darken-1" text @click="symbolRuleHandler().remove()"
                                                :loading="modalRemove.loading">remove</v-btn>
                                        </v-card-actions>
                                    </v-card>
                                </v-dialog>
                            </v-card>
                        </v-col>
                    </v-row>
            </v-container>
        </v-card>
    </div>
</template>
<script>
import { permission } from "@components/mixins/permission";
import Breadcrumbs from "@components/Breadcrumbs";
import { snackbar } from "@components/mixins/snackbar";
import * as apiSettingsBookRule from "@components/api/pk/settings/book-rule";
import { mapState } from "vuex";

export default {
    components: { Breadcrumbs },
    mixins: [permission, snackbar],
    data () {
        return {
            selectedBrokerId: -1,
            selectedBroker: "",
            selectedRule: [],
            btnLoading: false,
            tableData: {
                headers: [
                    { text: "Endfix", value: "endfix" },
                    { text: "", value: "action" }
                ],
                dataSource: [],
                itemsPerPage: 20,
                search: "",
                selectedData: []
            },
            modalRemove: {
                visible: false,
                loading: false,
                title: ""
            },
            modalNew: {
                visible: false,
                loading: false,
                title: "",
                symbolRule: "",
                updateData: ""
            }
        };
    },
    watch: {
        selectedBrokerId (nv, ov) {
            this.symbolRuleHandler().load();
        },
        "modalNew.symbolRule" (nv, ov) {
            this.updateData = "";
        }
    },
    computed: {
        ...mapState(["brokerMap"])
    },
    methods: {
        triggerNew () {
            this.modalNew.visible = true;
            this.modalNew.title = "New Symbol Rule";
        },
        triggerRemove () {
            this.modalRemove.visible = true;
            this.modalRemove.title = "Removal Confirm";
        },
        /**
         * 控制symbol rule服务器的增删改查
         * @return {[type]} [description]
         */
        symbolRuleHandler () {
            const self = this;

            return {
                load () {
                    self.tableData.dataSource = [];
                    const params = { broker_id: self.selectedBrokerId };

                    apiSettingsBookRule.fetch(params).then(res => {
                        self.tableData.dataSource = res.data;
                    });
                },
                update () {
                    const localKey = "brokerSymbolRule";

                    if (self.modalNew.symbolRule === "" ||
                        self.modalNew.symbolRule === null ||
                        self.modalNew.symbolRule === undefined) {
                        self.snackBarDanger("Please fill in symbol rule");
                        return;
                    }
                    self.modalNew.loading = true;
                    const oldData = self.tableData.dataSource.map(item => item.endfix);
                    const newData = self.modalNew.symbolRule.split(",");
                    let newList = newData.concat(oldData);
                    newList = Array.from(new Set(newList));

                    const params = { endfix: newList, broker_id: self.selectedBrokerId };

                    apiSettingsBookRule.update(params).then(res => {
                        setTimeout(() => {
                            self.modalNew.loading = false;
                            self.modalNew.visible = false;

                            self.symbolRuleHandler().load();

                            const localData = JSON.parse(localStorage.getItem(localKey));
                            for (let i = 0; i < localData.length; i++) {
                                if (localData[i].broker_id === self.selectedBrokerId) {
                                    localData[i].rule = newList;
                                    break;
                                }
                            }
                            localStorage.setItem(localKey, JSON.stringify(localData));
                        }, 500);
                    });
                },
                remove () {
                    const localKey = "brokerSymbolRule";

                    const removeData = self.tableData.selectedData.map(item => item.endfix);
                    self.modalRemove.loading = true;

                    const filteredData = self.tableData.dataSource
                        .filter(item => !removeData.includes(item.endfix))
                        .map(item => item.endfix);

                    const params = { endfix: filteredData, broker_id: self.selectedBrokerId };

                    apiSettingsBookRule.update(params).then(res => {
                        setTimeout(() => {
                            self.modalRemove.loading = false;
                            self.modalRemove.visible = false;

                            const localData = JSON.parse(localStorage.getItem(localKey));
                            for (let i = 0; i < localData.length; i++) {
                                if (localData[i].broker_id === self.selectedBrokerId) {
                                    localData[i].rule = filteredData;
                                    break;
                                }
                            }
                            localStorage.setItem(localKey, JSON.stringify(localData));
                            self.symbolRuleHandler().load();
                        }, 500);
                    });
                }
            };
        }
    },
    created () {
        // 获取当前账户对应权限的broker及broker id
        const brokerPermission = this.loadDefaultBrokerData();
        this.selectedBrokerId = brokerPermission.selectedBrokerId;
        this.selectedBroker = brokerPermission.selectedBroker;
    },
    mounted () {
        this.symbolRuleHandler().load();
    }
};
</script>
